.front-site  a, .front-site button { outline: none !important; text-decoration: none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important; }
@font-face { font-family: "Segoe UI"; src: url("../fonts/SegoeUIBlack.eot"); src: url("../fonts/SegoeUIBlack.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUIBlack.woff2") format("woff2"),  url("../fonts/SegoeUIBlack.woff") format("woff"),  url("../fonts/SegoeUIBlack.ttf") format("truetype"),  url("../fonts/SegoeUIBlack.svg#SegoeUIBlack") format("svg"); font-weight: 900; font-style: normal; }
@font-face { font-family: "Segoe UI"; src: url("../fonts/SegoeUI.eot"); src: url("../fonts/SegoeUI.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUI.woff2") format("woff2"),  url("../fonts/SegoeUI.woff") format("woff"),  url("../fonts/SegoeUI.ttf") format("truetype"),  url("../fonts/SegoeUI.svg#SegoeUI") format("svg"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Segoe UI"; src: url("../fonts/SegoeUI-Bold.eot"); src: url("../fonts/SegoeUI-Bold.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUI-Bold.woff2") format("woff2"),  url("../fonts/SegoeUI-Bold.woff") format("woff"),  url("../fonts/SegoeUI-Bold.ttf") format("truetype"),  url("../fonts/SegoeUI-Bold.svg#SegoeUI-Bold") format("svg"); font-weight: bold; font-style: normal; }
@font-face { font-family: "Segoe UI"; src: url("../fonts/SegoeUI-Light.eot"); src: url("../fonts/SegoeUI-Light.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUI-Light.woff2") format("woff2"),  url("../fonts/SegoeUI-Light.woff") format("woff"),  url("../fonts/SegoeUI-Light.ttf") format("truetype"),  url("../fonts/SegoeUI-Light.svg#SegoeUI-Light") format("svg"); font-weight: 300; font-style: normal; }
@font-face { font-family: "Segoe UI"; src: url("../fonts/SegoeUI-Semibold.eot"); src: url("../fonts/SegoeUI-Semibold.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUI-Semibold.woff2") format("woff2"),  url("../fonts/SegoeUI-Semibold.woff") format("woff"),  url("../fonts/SegoeUI-Semibold.ttf") format("truetype"),  url("../fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold") format("svg"); font-weight: 600; font-style: normal; }
@font-face { font-family: "Segoe UI Semilight"; src: url("../fonts/SegoeUI-Semilight.eot"); src: url("../fonts/SegoeUI-Semilight.eot?#iefix") format("embedded-opentype"),  url("../fonts/SegoeUI-Semilight.woff2") format("woff2"),  url("../fonts/SegoeUI-Semilight.woff") format("woff"),  url("../fonts/SegoeUI-Semilight.ttf") format("truetype"),  url("../fonts/SegoeUI-Semilight.svg#SegoeUI-Semilight") format("svg"); font-weight: 300; font-style: normal; }
.front-site .overlay { position: fixed; z-index: 3; width: 100%; height: 100%; background: rgb(7 9 15 / .67); visibility: hidden; opacity: 0; }
.front-site .btn-primary {background: #00245F !important;color: #fff !important;border: none;font-weight: 600;line-height: 1.2;padding: 9px 12px;min-width: 120px;font-size: 14px;}
.front-site .btn-secondary {background: #a8b8cf !important;color: #fff !important;border: none;font-weight: 600;line-height: 1.2;padding: 9px 12px;min-width: 120px;font-size: 14px;}
.front-site .btn-primary:hover, .front-site .btn-primary:focus { background: #00245b !important; color: #fff !important; opacity: 0.8; }
.front-site .btn-secondary:hover, .front-site .btn-secondary:focus { background: #a8b8cf !important; color: #fff !important; opacity: 0.8; }
.front-site .btn-primary+.btn-secondary {margin-left: 10px;}
.front-site .btn-large {padding: 12px 24px;font-size: 16px;line-height: 1.2;}
.front-site .btn i.ml { margin-left: 10px; }
.front-site .red-color { color: #ef4744; }
/* Common Css Start */
body.front-site  { font-family: 'Segoe UI'; font-weight: 400; background-color: #F3F6F9; }
.front-site img { max-width: 100%; }
.front-site .container { max-width: 1220px; }
.front-site .heading-v-1 {margin-bottom: 28px;font-size: 36px;}
.front-site .link-absolute { position: absolute; z-index: 1; width: 100%; height: 100%; top: 0; left: 0; }
.sec-ptb{padding: 60px 0;}
/* Common Css End */

/* Header Css Start */
.front-site #toogle-btn { display: none; }
/* .front-site .header-main {padding: 15px 0;background: #fff;position: relative;z-index: 9;line-height: 1.2;} */
.front-site .header-main {
  padding: 15px 0;
  background: #fff;
  position: fixed;
  z-index: 333;
  line-height: 1.2;
  width: 100%;
  top: 0;
}
.front-site .logo-head {max-width: 260px;}
.front-site .main-nav-wrap > ul {
  font-size: 15px;
  text-transform: capitalize;
  }
.front-site .main-nav-wrap > ul > li > a {
  color: #313247;
  font-weight: 500;
  padding: 10px 5px;
  display: block;
  }
.front-site .main-nav-wrap > ul > li > a:hover {   background: #00245b;
  color: #fff;}
.front-site .main-nav-wrap > ul > li +li { margin-left: 10px; }
.front-site.nav-open .overlay { visibility: visible; opacity: 1; }
body.front-site.nav-open { overflow: hidden; position: fixed; }
/* Header Css End */

/* Footer Css Start */
.front-site .footer-wrap { background: #00245b; padding: 60px 0; }
.front-site .copyright-wrap { background: #484848; padding: 20px 0; }
.front-site .footer-main-wrap, .front-site .footer-main-wrap a { color: #fff; }
.front-site .social-icon img { max-height: 50px; }
.front-site .social-icon li+li { margin-left: 30px; }
.front-site .footer-wrap h6 { margin: 0 0 10px 0; font-weight: 700; font-size: 18px; }
.front-site .footer-wrap .link-list a { color: #bfbfbf; font-weight: 300; font-size: 16px; }
.front-site .footer-wrap .link-list a:hover { color: #fff; }
.front-site .row-links { margin-top: 30px; }
.front-site .footer-wrap p { font-size: 18px; }
/* Footer Css End */



/* Page Banner Css Start */
.front-site {
  padding-top: 69px;
}
.front-site .page-banner-wrap { padding: 80px 0 60px; background-size: cover; background-repeat: no-repeat; background-position: center; position: relative;}
/* .front-site .color-divider { position: absolute; left: 0; top: 0; } */
.front-site .color-divider {
  position: fixed;
  left: 0;
  top: 69px;
  z-index: 22;
  width: 100%;
}
.front-site .color-divider img { display: block; }
.front-site .page-banner-content { padding: 30px; color: #fff; background-repeat: no-repeat; background-size: cover; line-height: 1.2; background-position: right; margin-bottom: 40px; }
.front-site .page-banner-content .heading-v-1 {font-size: 42px;margin-bottom: 15px;line-height: 1;}
.front-site .page-banner-content p {font-size: 20px;margin: 0;line-height: 1.3;font-weight: 300;}
.front-site  li.logo-1 img, .front-site  li.logo-3 img {max-height: 50px;}
.front-site li.logo-2 img { max-height: 80px; }
.front-site .logo-list-wrap li+li {margin-left: 30px;}
/* Page Banner Css End */


/* index Page Css Start */
.front-site .multiple-join-wrap { padding: 60px 0 120px; background-repeat: no-repeat; background-size: cover; background-position: bottom; background-color: #c6d0d9; position: relative; overflow: hidden; }
.front-site .join-col-left .row, .front-site .join-col-right .row { margin: 0 -6px; }
.front-site .join-col-left .row [class*="col-"], .front-site .join-col-right .row [class*="col-"] { padding: 0 6px; }
.front-site .join-bx-col { margin: 6px 0; }
.front-site .join-bx { text-align: center; padding: 20px 5px; height: 100%; line-height: 1.2; transition: all 300ms; position: relative; }
.front-site .join-bx:hover { /* transform: scale(1.05); */
transform: scale(0.97); }
.front-site .join-bx-icon img { max-height: 45px; }
.front-site .join-bx-icon { height: 45px; margin-bottom: 15px; display: flex; align-items: center; justify-content: center; }
.front-site .join-bx-detail h6, .front-site .join-bx-detail p { margin: 0; }
.front-site .join-bx-detail h6 { font-weight: 600; font-size: 16px; }
.front-site .join-bx-detail p { font-size: 12px; }
/* 
.shape-join {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.shape-girl {
  left: -10px;
  bottom: -30px;
}

.shape-boy {
  right: -30px;
  bottom: -110px;
}

.shape-join img {
  max-height: 100%;
} */
/* index Page Css End */





.front-site .profile-change input { position: absolute; width: 100%; height: 100%; margin: 0; opacity: 0; cursor: pointer; z-index: 1; top: 0; left: 0; text-indent: -999999px; }
.front-site .profile-picture {background: #a8b8cf;height: 115px;}
.front-site .profile-change {position: relative;max-width: 170px;}
.front-site .profile-change:hover .profile-chnage-text { background: #00245b !important; color: #fff !important; opacity: 0.8; }
.front-site .profile-chnage-text { text-align: center; font-weight: 600; background: #103366; color: #fff; padding: 15px; line-height: 1.4; }
/* Form Common Css Start */
.front-site .form-group > label {font-size: 15px;color: #303146;line-height: 1.2;margin: 0 0 10px 0;}
.front-site .form-group > .form-control, .front-site .react-datepicker-wrapper .form-control {     background: #c6d0d8;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  height: inherit;
  line-height: 1.2;
  box-shadow: none !important;
  outline: none !important;
  resize: none;
  min-height: 42px;
  font-size: 14px;}

  .front-site .form-group > .form-control.form-control.form-control-light, .front-site .form-group  .form-control.form-control.form-control-light {
    background-color: #EEF1F5;
    border-color: #EEF1F5;
    color: #252423;
}
  .front-site .form-group > select.form-control { background-color: #c6d0d8; background-image: url(../images/select-dropdown.svg); -webkit-appearance: none; -moz-appearance: none; appearance: none; background-repeat: no-repeat; background-position: calc(100% - 10px) center; }



  .front-site .form-group > select.form-control.form-control.form-control-light, .front-site .form-group  select.form-control.form-control.form-control-light {
    background-color: #EEF1F5;
    background-image: url(../images/select-dropdown.svg); 
    -webkit-appearance: none; -moz-appearance: none; appearance: none; background-repeat: no-repeat; background-position: calc(100% - 10px) center;
}
  .front-site .form-group > textarea.form-control { min-height: 94px; }
.front-site .form-box {background: #eaf6ff;padding: 40px 60px;border-radius: 10px;}
.front-site .form-group { margin-bottom: 30px; }
.front-site .form-box h4 {font-size: 24px;margin: 0 0 30px 0;font-weight: 600;}
.front-site .form-box hr { margin: 50px 0; border-top: 1px solid #b8bbbd; }
/* Form Common Css End */



.front-site .custom-checkbox {padding-left: 30px;line-height: 1.2;font-size: 16px;color: #303146;min-height: 28px;}
.front-site .custom-control-label::before {border-radius: 0 !important;width: 20px;height: 20px;background-color: #c6d0d8;border: 1px solid #787b80 !important;left: -30px;top: 0;box-shadow: none !important;outline: none !important;}
.front-site .custom-control-label::after {left: -30px;top: 0;width: 20px;height: 20px;box-shadow: none !important;outline: none !important;}
.front-site .custom-control-input:checked~.custom-control-label::before { background-color: #00245f; border-color: #00245f; }
.front-site .custom-control-input:focus:not(:checked)~.custom-control-label::before { }
.front-site .custom-checkbox a { color: #007bff; font-weight: 600; }
.front-site .register-wrap { padding: 60px 0; }
.front-site .page-heading .heading-v-1 { margin-bottom: 15px; }
.front-site .page-heading p {font-size: 20px;margin-bottom: 30px;}






/****Login Page Css Start***/
.front-site .portal-login {padding: 100px 0;background-size: cover;    background-repeat: no-repeat;
  background-position: bottom;}
.front-site .portal-login .container { max-width: 880px; padding: 15px; }
.front-site .portal-login-card {background: none;border: none;-webkit-box-shadow: none;-moz-box-shadow: none;box-shadow: none;/* background: #eaf6ff; */border-radius: 10px;     max-width: 767px;
  border-radius: 10px;
  margin: 0 auto;}
.front-site .portal-login-card .card-header {
    background: none;
}
.front-site .card-header-img {max-width: 340px;margin: 0 auto 0;padding: 15px;}
.front-site .portal-login-card .card-body {background: #eaf6ff;padding: 30px;border-radius: 10px;}
.front-site .portal-login-card .card-body-inr { max-width: 360px; margin: 0 auto; line-height: 1.2; }
.front-site .portal-login-info h1 { font-size: 26px; font-weight: bold; letter-spacing: 2px; }
.front-site .portal-login-info h3 {font-size: 32px;margin: 30px 0 0 0;font-weight: bold;letter-spacing: 1px;color: #00245F;text-transform: capitalize;}
.front-site .portal-login-info p { font-size: 16px; letter-spacing: 1px; margin: 10px 0 30px; }
.front-site .portal-login-form .input-group { margin-bottom: 20px; }
.front-site .portal-login-form .input-group .form-control {background: #c6d0d8;font-size: 14px;line-height: 1.2;height: inherit;border: 1px solid #c6d0d8;padding: 12px 15px;min-height: 40px;-webkit-border-radius: 2px;-moz-border-radius: 2px;border-radius: 2px;}
.front-site .portal-login-form .input-group .form-control.is-invalid { border-color: #dc3545 !important; }
.front-site .portal-login-form .input-group .input-group-prepend .input-group-text {background: none;border: none;color: #00245F;font-size: 18px;padding: 0;width: 30px;}
.front-site .portal-login-form .custom-control.custom-checkbox { margin: 20px 0 30px; margin-left: 30px; }
.front-site .portal-login-form .custom-control-label { line-height: 1.2; font-size: 16px; font-weight: 600; cursor: pointer; padding-left: 5px; }
.front-site .custom-control-input:focus~.custom-control-label::before { outline: none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; box-shadow: none !important; }
.front-site .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after{ top: 0; width: 20px !important; height: 20px !important; -webkit-border-radius: 0 !important; -moz-border-radius: 0 !important; border-radius: 0 !important; border-color: #4a7dbf; -webkit-background-size: 10px 10px; -moz-background-size: 10px; -o-background-size: 10px; background-size: 10px; background-repeat: no-repeat; background-position: center; }
.front-site .custom-control-input:checked~.custom-control-label::before {background: #00245f;border-color: #00245f;}
.front-site .custom-control-input:checked~.custom-control-label {color: #00245f;}
.front-site button.btn.btn-white-bordered.btn-submit-info { padding: 10px 20px; font-size: 18px; text-transform: uppercase; }
.front-site .portal-login-card .card-footer {padding: 30px;border: none;-webkit-box-shadow: none;-moz-box-shadow: none;box-shadow: none;text-align: center;justify-content: center;background: none;}
.front-site .portal-login-card .card-footer a {color: #00245f;font-size: 16px;}
.front-site .portal-login-card .card-footer a:hover {color: #00245f;text-decoration: underline !important;}



/****Login Page Css End***/

/* search Page Css Start */
.front-site .search-list-ul { }
.front-site .search-list-ul > li { }
.front-site .search-list-ul > li+li { border-top: 1px solid rgb(112 112 112 / 22%); margin-top: 18px; padding-top: 18px; }
.front-site .search-list-wrap { padding: 20px 15px; background: #fff; border-radius: 3px; border: 1px solid #CBCED0; }
.front-site .search-list-box { display: flex; align-items: flex-start; }
.front-site .search-icon { width: 76px; height: 76px; border: 1px solid rgb(112 112 112 / 20%); margin-right: 15px; }
.front-site .search-icon img { height: 100%; object-fit: cover; }
.front-site .search-detail { width: calc(100% - 91px); }
.front-site .search-detail h4 { font-size: 14px; font-weight: bold; color: #484644; margin: 0 0 5px 0; line-height: 1.2; }
.front-site .search-detail p { font-size: 14px; margin: 0 0 5px 0; }
.front-site .badge.badge-light { background: #DFECFF; font-weight: normal; font-size: 13px; color: #484644; padding: 3.5px 10px 4.5px; line-height: 1.2; }
.front-site .search-heading { margin-bottom: 15px; }
.front-site .heading-v-2 { font-size: 16px; color: #252423; font-weight: bold; }
.front-site .search-heading .btn-primary {padding: 9px;border: 1px solid #D7DEE8;background: #fff !important;color: #484644 !important;}
.front-site .search-heading .btn-primary[aria-expanded="true"], .search-heading .btn-primary[aria-expanded="true"]:focus { background: #00245b !important; color: #fff !important; }
.front-site .search-heading .btn-primary i { margin-right: 5px; }
.front-site .search-form .row { margin: 0 -10px; }
.front-site .search-form .row .row { }
.front-site .search-form .row [class*="col-"] { padding: 0 10px; }
.front-site .search-form .collapse.show .card.card-body { border: 1px solid #CBCED0; }
.front-site .search-form.collapse.show+.search-list-wrap { margin-top: 15px; }
.front-site .search-form .form-group > label { font-size: 14px !important; color: #484644; }
.front-site .search-form .form-group .form-control { font-size: 14px !important; }
.front-site  .search-form .form-group .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #252423;
}
.front-site .search-form .form-group .form-control::-moz-placeholder { /* Firefox 19+ */
color: #252423;
}
.front-site .search-form .form-group .form-control:-ms-input-placeholder { /* IE 10+ */
color: #252423;
}
.front-site .search-form .form-group .form-control:-moz-placeholder { /* Firefox 18- */
color: #252423;
}
/* search Page Css End */




/* Pagination Custom Css Start */
.front-site  .custom-pagination .btn { border: 1px solid #d7dee8; color: #484644; font-size: 14px; font-weight: 600; padding: 5px; line-height: 1.2; background: #fff; -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; -webkit-box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important; -moz-box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13) !important; box-shadow: 0px 2px 2.97px 0.03px rgb(125 125 125 / 13%) !important; margin: 4px; width: 30px; height: 30px; }
.front-site  .custom-pagination .btn:hover, .front-site .custom-pagination .btn.active { background: #00245F !important; color: #fff; }
.front-site  .custom-pagination .btn:focus { opacity: 0.8; }

/* Pagination Custom Css End */




.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #103366;
}

.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected, .react-datepicker__month-text--in-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--selected, .react-datepicker__quarter-text--in-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--selected, .react-datepicker__year-text--in-range, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--selected {
  background-color: #103366;
}






.front-site .portal-404 .portal-login-card .card-body {
  min-height: 367px;
  display: flex;
  align-items: center;
}

.front-site .portal-404 .portal-login-card h3 {
  font-size: 52px;
}
.account-panel-site .user-profile .user-drop-down-img {
  width: 40px !important;
  height: 40px !important;
}

.custom-pagi-blk {
  display: flex;
  justify-content: space-between;
}

.custom-pagi-blk .pages-block {
  display: flex;
  align-items: center;
}

.custom-pagi-blk .pages-block label {
  margin-right: 5px;
}
.logo-head {
  max-width: 140px !important;
}

.name-blue-color a{color: #6B99D8 !important;}
.welcome-user .name-blue-color{color: #6B99D8 !important; font-weight: 600;}
.welcome-user.d-flex.align-items-center .welcome-user-name span.name-blue-color{color: #6B99D8;}
.user-profile .user-drop-down-img {border: 1px solid #00245b; width: 40px; height: 40px; border-radius: 50%; display: inline-block; cursor: pointer; }
.web-header-img-sec{position: relative;}

.web-header-img-sec .welcome-user .welcome-user-img img {border-radius: 50%;height: 100%;width: 100%;object-fit: cover;object-position: center;}
.web-header-img-sec .profile-img-child img {height: 100%;width: auto;min-width: 100%;object-fit: cover;}
.web-header-img-sec .welcome-user span.portal-status-icon {font-size: 10px;background: #fff;border-radius: 50%;position: absolute;bottom: 0;right: 0;color: #92c353;}
.web-header-img-sec .dropdown-toggle::after {display: none;}
.web-header-img-sec .user-profile-dropdown-menu.show {padding: 10px 0px;transform: none !important;top: 100% !important;right: 0 !important;left: auto !important;border: 1px solid #d7dee8;border-radius: 2px;box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);min-width: 360px;}
.web-header-img-sec .profile-img-child {width: 50px;height: 50px;overflow: hidden;border-radius: 50%;border: 1px solid #d7dee8;box-shadow: 0px 2px 2.97px 0.03px rgba(125, 125, 125, 0.13);}
.web-header-img-sec .profile-img-parent {margin-right: 10px;}
.web-header-img-sec .login-user-profile {margin: 10px 20px;}
.profile-name-text {font-weight: bold;font-size: 14px;line-height: 1.2;}
.web-header-img-sec .change-picture button {font-size: 12px;padding: 0;margin: 0;background: none;border: none;}
.web-header-img-sec .change-picture {line-height: 1;}
.web-header-img-sec .user-profile-dropdown-menu li a {padding: 8px 20px;display: block;font-size: 14px;width: 100%;text-align: left;border: none;background: none;color: #484644;line-height: 1.2;cursor: pointer;}
.web-header-img-sec .user-profile-dropdown-menu li a i {font-size: 14px;min-width: 20px;display: inline-block;}
.web-header-img-sec .user-profile-dropdown-menu li a:hover {background: #e6ecf2;}
.web-header-img-sec .user-profile-dropdown-menu li.divider {margin: 9px 0;overflow: hidden;background-color: #d7dee8;height: 1px;}





.account-panel-site .cards-main-block .rs-full-side-inr {
  padding: 0;
}
.account-panel-site .cards-main-block .tab-pane .card.card-theme {
  padding: 0;
  background: transparent;
}
.account-panel-site .cards-main-block .card.card-theme > .card-header {
  background: transparent;
  color: #252423;
  padding: 15px 0;
}
.account-panel-site .cards-main-block .card.card-theme > .card-body {
  background: #fff;
}
.account-panel-site .cards-main-block .tab-pane .card.card-theme.card-info-theme {
  background: #fff;
  padding: 20px;
}
.account-panel-site .card.card-theme.additional-card {
  margin-top: 25px;
}

.ReactModalPortal .ReactModal__Content .form-group label{overflow: hidden;}
.banner-video-box .banner-video-blk {position: absolute;width: 100%;top: 25px;height: 100%;right: 0;overflow: hidden;}
.page-banner-wrap.banner-video-box {height: 403px;}
.banner-video-box .banner-video-blk div {height: auto !important;width: auto !important;}
.front-site .page-banner-wrap.banner-video-box .color-divider {height: 25px;}
.front-site .page-banner-wrap.banner-video-box .color-divider img {height: 100%;width: 100%;}
.banner-video-box .banner-video-blk div video,.banner-video-box .banner-video-blk div iframe {height: 100% !important;width: 101% !important;}


.input-group-date {display: flex;width: 100%;margin: 0 -3px;}
.input-group-date div {flex: 1;margin: 0 3px;}
.input-group-date select.form-control {background: #c6d0d8;border: none;border-radius: 5px;padding: 10px 15px;height: inherit;line-height: 1.2;box-shadow: none !important;outline: none !important;resize: none;min-height: 30px;font-size: 14px;}
.input-group-date select.form-control {background-color: #c6d0d8;background-image: url(/static/media/select-dropdown.39b83fed.svg);-webkit-appearance: none;appearance: none;background-repeat: no-repeat;background-position: calc(100% - 10px) center;}


.profile-details-box {
  background: #f7f7f7;
  padding: 20px;
}
.profile-details-box h6 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}
.profile-details-box p {
  margin: 0;
}

.front-site.full-video-box {padding: 0;}
.front-site.full-video-box header.header-main {background: rgb(255 255 255 / 90%);}
.front-site.full-video-box .page-banner-wrap.banner-video-box {padding: 174px 0 80px;height: 535px;}
.front-site.full-video-box .banner-video-box .banner-video-blk {top: 0;}
.front-site.full-video-box header.header-main.sticky  {
  background: #fff;
}

/* dev css */
.banner-txt h1 {  font-size: 48px;display: inline;background-color: #06172a;font-weight: 700;color: #fff;box-shadow: 10px 0 0 #06172a, -10px 0 0 #06172a; }
.banner-txt p { color: #fff;font-size: 20px;margin-top: 15px; }
.list-logo a { display: block;margin-bottom: 20px;}
.n-logo-1 img { max-width: 336px; }
.n-logo-2 img { max-width: 200px; }
.n-logo-3 img { max-width: 270px; }
.banner-video-box  { box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 60%);z-index: 1;}
.front-site .page-banner-wrap.banner-video-box .color-divider { height: 7px; }
.search-main-wrap { background-image: url("/static/media/join-bg.27213c6c.jpg");padding: 100px 0;background-size: cover;background-repeat: no-repeat;background-position: bottom; }
.search-main-wrap { padding-top: 40px;}
.front-site .portal-login,.front-site .register-wrap { padding:40px 0; }
.front-site .multiple-join-wrap { padding: 40px 0 120px; }
.front-site .heading-v-1 { margin-bottom: 40px; }
.front-site .portal-login .container { padding: 0;}
/* dev css */

.front-site.full-video-box.admin-header-login {
  padding-top: 69px;
}

.bg-dashboard { background-image: url(/static/media/join-bg.27213c6c.jpg);background-size: cover;background-repeat: no-repeat;background-position: top; }

.form-icon-group .custom-select-box {
  padding: 0 !important;
}


.form-icon-group .custom-select-box .css-yk16xz-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.custom-select-box .css-1pahdxg-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.custom-select-box .css-1wa3eu0-placeholder {
  color: #495057;
}

.custom-select-box .css-tlfecz-indicatorContainer .css-6q0nyr-Svg {
  fill: #495057;
}

.custom-select-box .css-g1d714-ValueContainer {
  padding: 0 12px;
}

.custom-select-box .css-1pahdxg-control .css-1wa3eu0-placeholder {
  padding-left: 20px !important;
}

.custom-select-box .css-1pahdxg-control .css-1wa3eu0-placeholder:before {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  top: 2px;
  position: absolute;
  left: 0;
  color: #4a7dbf;
}

.custom-select-box .css-26l3qy-menu {
  z-index: 2;
}

.as-buttons button+button {
  display: block;
}

.profile-box-2 .custom-select-box .css-yk16xz-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.card.card-profile-info-card .profile-box-2 .custom-select-box {
  padding: 0;
}

.form-group-blk .custom-select-box .css-yk16xz-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.form-group-blk .custom-select-box {
  padding: 0 !important;
}

.form-icon-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  width: 100%;
  align-items: center; 
}

.form-icon-group .form-icon {
  background: none;
  border: none;
  color: #4a7dbf;
  font-size: 18px;
  padding: 8px 0 0;
  width: 32px;
  text-align: initial;
}

.form-icon-group .form-control {
  background-color: #eef1f5;
  font-size: 14px;
  line-height: 1.2;
  height: inherit;
  border: 1px solid #eef1f5;
  padding: 6px 15px;
  min-height: 42px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.form-icon-group .form-grp {
  width: 100%;
}

.card.card-profile-info-card .form-icon-group .form-control {
  min-height: 42px;
}

.form-icon-group .custom-select-box {
  padding: 0 !important;
}

.form-icon-group .custom-select-box .css-yk16xz-control {
  height: 42px !important;
  border: none !important;
  background: transparent !important;
}

.form-icon-group select.form-control {
  padding: 0 10px !important;
}

.course-details-tab-sec .form-icon-group {
  display: block;
}

.form-control.custom-select-box.css-2b097c-container {
  border: none;
  border-radius: .25rem;
}

.input-group-wrap{
  flex-wrap: inherit;
}

.is-invalid .invalid-feedback{
  display: block;
}

.is-invalid .form-control.css-2b097c-container {border-color: #dc3545 !important;}

.multi__wrap_list .css-g1d714-ValueContainer {
  padding: 0px 12px;
  min-height: calc(1.5em + 0.75rem + 2px);
  flex-wrap: wrap;
  width: 450px;
}
.form-control.custom-select-box.multi__wrap_list.css-2b097c-container  .css-yk16xz-control {
  height: 100% !important;
  border: none !important;
  background: transparent !important;
}
.custom-select-box.multi__wrap_list .css-1pahdxg-control{ height: 100% !important;}